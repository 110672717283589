import {state} from './state.js';
export async function getPrice() {
    
    document.getElementById('priceUpdate3').style.display = "none"
    document.getElementById('priceUpdate4').style.display = "none"
    let testPrice = await Moralis.Web3API.token.getTokenPrice({chain: "polygon", address: state.contractAddress})
    let priceFix = math.format(testPrice.usdPrice, {notation: 'fixed'})
    let options = { chain: 'polygon', address: state.walletAddress}
    let balances = await Moralis.Web3API.account.getTokenBalances(options);
    const resultBalance = balances.find( ({ token_address }) => token_address === state.contractAddress );
    const resultBalanceNumber = Moralis.Units.FromWei(resultBalance.balance)
    const balanceIntl = Intl.NumberFormat('en-US').format(resultBalanceNumber)
    state.totalCoinAmountHeld = balanceIntl
    state.currentCoinPrice = priceFix
    const totalBalanceUSD = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(resultBalanceNumber * priceFix)
    state.totalCoinValueUSD = totalBalanceUSD
    console.log(balanceIntl, totalBalanceUSD)
    let loadingDisplay = document.getElementById('loadingDisplay')
    loadingDisplay.style.display = "none"
    let questions = document.getElementById('mainDisplay')
    questions.style.display = 'flex'
    document.getElementById('priceUpdate3').style.display = ""
  console.log(state)
  }