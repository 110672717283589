export var state = {
    contractAddress: "0x8a953cfe442c5e8855cc6c61b1293fa648bae472",
    walletAddress: "0xa7793af392bd5609a1e6b8fe57f1e17d424a84d2",
    currentCoinPrice: '',
    totalCoinAmountHeld: '',
    totalCoinValueUSD: '',
    tweetIt: '',
    valueChange: '',
}

window.state = state